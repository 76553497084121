/**
 * Hooks exported here are imported and initialized in `useGlobalABTests` hook
 */
import { useABTest_ForcePhoneNumber } from 'lib/ab-tests/tests/global/LIVE19141_ForceIEAndFRCouplesToEnterPhoneNumberBeforeSendingEnquiries';
import { useABTest_CakeQuizSearchIntercept } from 'lib/ab-tests/tests/global/LIVE-19024_CakeQuizSearchIntercept';
import { useABTest_HairAndBeautyQuizSearchIntercept } from 'lib/ab-tests/tests/global/LIVE-19024_HairAndBeautyQuizSearchIntercept';
import { useABTest_VideographerQuizSearchIntercept } from 'lib/ab-tests/tests/global/LIVE-19024_VideographerQuizSearchIntercept';
import { useABTest_EnableGlobalSearch } from 'lib/ab-tests/tests/global/LIVE-19547_EnableGlobalSearch';
import { useABTest_ApplyOnboardingFilterPreferencesToSearchResults } from 'lib/ab-tests/tests/global/LIVE-21094_ApplyOnboardingFilterPreferencesToSearchResults';
import { useABTest_BrochureRequestFastResponse } from 'lib/ab-tests/tests/global/LIVE-21330_BrochureRequestFastResponse';
import { useABTest_FloristQuizSearchIntercept } from './LIVE-19024_FloristQuizSearchIntercept';
import { useABTest_PhotoQuizSearchIntercept } from './LIVE-19024_PhotoQuizSearchIntercept';

export const globalABTests: (() => void)[] = [
  useABTest_CakeQuizSearchIntercept,
  useABTest_FloristQuizSearchIntercept,
  useABTest_PhotoQuizSearchIntercept,
  useABTest_VideographerQuizSearchIntercept,
  useABTest_HairAndBeautyQuizSearchIntercept,
  useABTest_EnableGlobalSearch,
  useABTest_ForcePhoneNumber,
  useABTest_ApplyOnboardingFilterPreferencesToSearchResults,
  useABTest_BrochureRequestFastResponse,
];
