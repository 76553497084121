import { CSSProperties } from 'fela';
import customProperty from 'fela-plugin-custom-property';
import { FelaCSS, TFelaCSSSpacingProps } from '../components/fela/flowtypes';
import { getThemedSpacing } from '../fela-utils/get-spacing-value';
import { TSpacingStep, TSpacingValue } from '../themes/types';
import { colors, fonts } from '../themes/variables';

/**
 * @deprecated
 * Use fontDefaultSemiBold instead
 */
const fontPrimaryMixin = (fontSize: number | 'inherit') => ({
  fontFamily: fonts.default,
  fontWeight: 600,
  WebkitFontSmoothing: 'antialiased',
  fontSize: fontSize || null,
});

// Cross-check possible font weights with fonts loaded in packages/ui/src/fela-utils/getFelaRenderer.ts
const fontDefaultMixin =
  (fontWeight?: 300 | 400 | 600 | 700, fontStyle?: CSSProperties['fontStyle']) =>
  (fontSize: number | 'inherit') => ({
    fontFamily: fonts.default,
    WebkitFontSmoothing: 'antialiased',
    fontSize,
    ...(fontWeight && { fontWeight }),
    ...(fontStyle && { fontStyle }),
  });

const fontPlayfairMixin = (fontSize: number | 'inherit') => ({
  fontFamily: fonts.playfair,
  WebkitFontSmoothing: 'antialiased',
  fontSize,
  fontWeight: 800,
});

/**
 * @deprecated
 * Use fontDefault instead
 */
const fontSystemMixin = (fontSize: number | 'inherit') => ({
  fontFamily: fonts.system,
  WebkitFontSmoothing: 'antialiased',
  fontSize,
});

const spacingMixin = (propName: TFelaCSSSpacingProps) => (val: TSpacingValue) => {
  if (typeof val === 'undefined' || val === null) return {};

  return { [propName]: getThemedSpacing(val) };
};

const getShorthandSpacingValues = (val: TSpacingStep) => {
  const isArray = Array.isArray(val);

  return isArray
    ? val.map((item) => getThemedSpacing(item))
    : typeof val === 'number'
    ? [getThemedSpacing(val)]
    : val.split(' ').map((item) => getThemedSpacing(item));
};

const paddingMixin = (val: TSpacingStep): FelaCSS => {
  if (typeof val === 'undefined' || val === null) return {};

  const values = getShorthandSpacingValues(val);

  return {
    paddingTop: values[0],
    paddingRight: values[1] || values[0],
    paddingBottom: values[2] || values[0],
    paddingLeft: values[3] || values[1] || values[0],
  };
};

const paddingVerticalMixin = (val: TSpacingValue): FelaCSS => {
  if (typeof val === 'undefined' || val === null) return {};

  return {
    paddingTop: getThemedSpacing(val),
    paddingBottom: getThemedSpacing(val),
  };
};

const paddingHorizontalMixin = (val: TSpacingValue): FelaCSS => {
  if (typeof val === 'undefined' || val === null) return {};

  return {
    paddingLeft: getThemedSpacing(val),
    paddingRight: getThemedSpacing(val),
  };
};

const marginMixin = (val: TSpacingStep): FelaCSS => {
  if (typeof val === 'undefined' || val === null) return {};

  const values = getShorthandSpacingValues(val);

  return {
    marginTop: values[0],
    marginRight: values[1] || values[0],
    marginBottom: values[2] || values[0],
    marginLeft: values[3] || values[1] || values[0],
  };
};

const marginVerticalMixin = (val: TSpacingValue): FelaCSS => {
  if (typeof val === 'undefined' || val === null) return {};

  return {
    marginTop: getThemedSpacing(val),
    marginBottom: getThemedSpacing(val),
  };
};

const marginHorizontalMixin = (val: TSpacingValue): FelaCSS => {
  if (typeof val === 'undefined' || val === null) return {};

  return {
    marginLeft: getThemedSpacing(val),
    marginRight: getThemedSpacing(val),
  };
};

const transitionFastMixin = (prop: string) => ({
  fontFamily: `${prop} 0.15s ease-in-out`,
});

const transitionSlowMixin = (prop: string) => ({
  fontFamily: `${prop} 0.6s ease-in-out`,
});

const colorHoverMixin = (color: string) => ({
  ':hover': { color },
  ':active': { color },
  ':focus': { color },
});

/**
 * Mixing to apply hover styles only on non-touch devices Regular :hover on touch devices would
 * remain after tap
 * @see https://medium.com/@mezoistvan/finally-a-css-only-solution-to-hover-on-touchscreens-c498af39c31c
 */
const hoverMixin = (props: FelaCSS): FelaCSS => ({
  noTouchDevices: {
    ':hover': props,
  },
});

/**
 * Mixin required to allow :active state override :hover styles on non-touch devices
 */
const activeMixin = (props: FelaCSS): FelaCSS => ({
  ':active': props,
  noTouchDevices: {
    ':active': props,
  },
});

const defaultShimmerMixin = (_: true) => ({
  background: 'linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)',
  backgroundSize: '1000px 100%',
  animationDuration: '2s',
  animationIterationCount: 'infinite',
  animationName: {
    '0%': {
      backgroundPosition: '-1000px 0',
    },
    '100%': {
      backgroundPosition: '1000px 0',
    },
  } as unknown as FelaCSS['animationName'],
  animationTimingFunction: 'linear',
});

const thinScrollbarMixin = (_: true) => ({
  scrollbarColor: `${colors.space30} transparent`,
  scrollbarWidth: 'thin',
  scrollbarGutter: 'stable',
});

const customProperties = {
  fontDefaultLight: fontDefaultMixin(300),
  fontDefault: fontDefaultMixin(400),
  fontDefaultItalic: fontDefaultMixin(400, 'italic'),
  fontDefaultSemiBold: fontDefaultMixin(600),
  fontDefaultBold: fontDefaultMixin(700),
  fontPlayfair: fontPlayfairMixin,
  /** @deprecated - use fontDefaultSemiBold instead */
  fontPrimary: fontPrimaryMixin,
  /** @deprecated - use one of fontDefault mixins */
  fontSystem: fontSystemMixin,
  padding: paddingMixin,
  paddingLeft: spacingMixin('paddingLeft'),
  paddingRight: spacingMixin('paddingRight'),
  paddingTop: spacingMixin('paddingTop'),
  paddingBottom: spacingMixin('paddingBottom'),
  paddingVertical: paddingVerticalMixin,
  paddingHorizontal: paddingHorizontalMixin,
  margin: marginMixin,
  marginLeft: spacingMixin('marginLeft'),
  marginRight: spacingMixin('marginRight'),
  marginTop: spacingMixin('marginTop'),
  marginBottom: spacingMixin('marginBottom'),
  marginVertical: marginVerticalMixin,
  marginHorizontal: marginHorizontalMixin,
  transitionFast: transitionFastMixin,
  transitionSlow: transitionSlowMixin,
  colorHover: colorHoverMixin,
  shimmer: defaultShimmerMixin,
  thinScrollbar: thinScrollbarMixin,
  hover: hoverMixin,
  active: activeMixin,
};

export type FelaCustomProperties = {
  [Properties in keyof typeof customProperties]?: Parameters<
    (typeof customProperties)[Properties]
  >[0];
};

export default customProperty(customProperties);
